import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 659.000000 670.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,670.000000) scale(0.100000,-0.100000)">
					<path d="M3694 4925 c-125 -34 -258 -149 -199 -171 9 -4 33 8 58 28 64 51 89
65 152 84 123 35 271 3 362 -80 46 -41 58 -44 73 -16 8 15 5 24 -17 46 -110
105 -282 149 -429 109z"/>
					<path d="M3715 4817 c-114 -38 -203 -123 -150 -143 8 -3 32 8 54 25 22 17 61
41 86 52 91 40 241 12 307 -58 25 -27 56 -30 65 -7 8 22 -51 80 -115 111 -65
32 -183 41 -247 20z"/>
					<path d="M3728 4705 c-58 -21 -110 -64 -106 -89 4 -29 29 -31 59 -5 35 31 82
49 131 49 47 0 115 -27 137 -53 17 -21 47 -22 55 -2 8 23 -1 38 -39 64 -73 50
-163 63 -237 36z"/>
					<path d="M3095 4598 c-60 -49 -173 -141 -250 -203 -77 -62 -203 -164 -281
-226 -195 -158 -204 -166 -204 -181 0 -31 24 -38 122 -38 l98 0 0 -276 c0
-171 4 -283 10 -295 10 -18 28 -19 630 -19 341 0 621 1 622 3 1 1 5 133 8 292
l5 290 104 5 c86 4 106 8 115 23 12 20 0 33 -148 155 l-76 63 0 94 0 95 -64 0
c-55 0 -66 -3 -71 -19 -3 -10 -5 -23 -4 -27 6 -35 -19 -21 -153 88 -84 67
-155 125 -158 128 -25 26 -174 140 -183 139 -7 0 -61 -41 -122 -91z m98 -436
c15 -2 18 -10 15 -59 l-3 -58 -47 -5 c-72 -9 -80 -3 -81 63 -1 31 2 58 7 60
10 3 72 2 109 -1z m167 -62 l0 -60 -65 0 -65 0 0 60 0 60 65 0 65 0 0 -60z
m-155 -155 l0 -60 -64 0 -63 0 0 58 c0 31 1 59 1 62 1 3 29 5 64 3 l62 -3 0
-60z m155 0 l0 -65 -65 0 -65 0 0 65 0 65 65 0 65 0 0 -65z"/>
					<path d="M3760 4609 c-36 -15 -60 -39 -60 -61 0 -22 36 -24 64 -3 26 20 68 19
104 -2 31 -18 62 -12 62 11 0 41 -115 79 -170 55z"/>
					<path d="M3788 4509 c-22 -12 -23 -42 -2 -63 20 -20 27 -20 55 3 22 18 22 19
6 45 -19 28 -32 31 -59 15z"/>
					<path d="M2010 4318 c-85 -50 -202 -118 -260 -151 -58 -33 -121 -69 -141 -81
-20 -12 -86 -50 -148 -85 -125 -71 -150 -98 -133 -136 13 -28 -1 -19 262 -170
113 -65 289 -167 392 -227 104 -59 196 -108 205 -108 24 1 49 21 57 46 14 45
32 33 -404 285 -107 62 -222 128 -255 146 -33 19 -64 38 -68 43 -5 4 13 20 40
36 51 29 166 95 328 189 55 32 154 89 220 127 66 38 126 77 133 86 25 34 -8
92 -53 91 -11 0 -90 -41 -175 -91z"/>
					<path d="M4210 4390 c-48 -48 -23 -78 145 -172 44 -25 141 -80 215 -123 74
-43 186 -107 248 -143 61 -35 112 -65 112 -67 0 -2 -55 -34 -122 -73 -68 -39
-179 -103 -248 -143 -69 -40 -176 -102 -239 -138 -63 -36 -119 -74 -125 -83
-13 -21 2 -66 26 -82 29 -18 14 -26 648 342 254 148 249 144 236 197 -4 16
-36 40 -108 82 -57 33 -176 102 -266 154 -376 218 -468 269 -485 269 -9 0 -26
-9 -37 -20z"/>
					<path d="M1520 2829 c-47 -23 -177 -32 -188 -12 -12 20 -42 5 -42 -22 0 -13 7
-26 16 -29 23 -9 85 -7 122 3 17 5 32 6 32 1 0 -19 -129 -203 -151 -214 -29
-14 -48 -61 -31 -78 18 -18 52 5 88 60 170 261 161 249 198 256 34 6 63 36 49
50 -11 11 -57 4 -93 -15z"/>
					<path d="M1721 2824 c-35 -19 -48 -21 -64 -12 -16 8 -23 7 -34 -8 -12 -16 -11
-21 4 -31 21 -16 48 -17 74 -3 25 14 24 10 -12 -49 -30 -47 -35 -51 -70 -51
-44 0 -49 -12 -17 -34 l21 -15 -37 -61 c-20 -33 -34 -64 -31 -70 16 -26 42 -5
83 65 l42 75 71 0 71 0 -36 -64 c-42 -75 -44 -89 -14 -84 15 2 36 26 66 78 25
41 69 113 98 160 69 112 72 120 43 120 -19 0 -35 -18 -77 -85 l-54 -85 -64 0
c-45 0 -64 4 -64 13 0 7 20 42 45 79 25 37 45 72 45 78 0 16 -42 9 -89 -16z"/>
					<path d="M2165 2841 c-106 -26 -165 -102 -118 -153 16 -18 15 -20 -21 -54 -36
-34 -56 -70 -56 -100 0 -27 47 -64 82 -64 77 0 198 89 198 145 0 29 -26 34
-35 6 -13 -41 -126 -115 -168 -109 -30 4 -34 42 -7 75 29 37 96 80 136 88 25
4 34 11 34 26 0 18 -5 19 -64 13 -74 -8 -90 4 -55 42 29 31 97 53 108 36 10
-16 71 -4 78 16 13 33 -43 49 -112 33z"/>
					<path d="M2610 2839 c-78 -24 -136 -99 -76 -99 15 0 27 7 31 19 3 11 24 26 46
35 108 44 217 -11 195 -98 -10 -41 -78 -109 -138 -139 -58 -28 -128 -45 -128
-30 0 5 36 66 80 136 44 70 80 129 80 132 0 3 -10 5 -22 5 -18 0 -38 -23 -89
-103 -109 -168 -121 -201 -73 -213 99 -25 268 67 324 175 35 70 18 136 -42
167 -39 20 -141 27 -188 13z"/>
					<path d="M3162 2829 c-24 -12 -80 -60 -125 -105 -68 -70 -87 -84 -113 -84 -31
0 -60 -21 -47 -33 3 -4 15 -7 25 -7 16 0 15 -5 -9 -48 -18 -33 -24 -54 -19
-68 7 -19 8 -19 24 2 9 12 32 42 49 68 31 44 34 46 83 46 49 0 49 0 40 -25 -5
-14 -16 -38 -25 -55 -20 -39 -19 -50 5 -50 14 0 30 23 64 93 106 218 129 287
99 287 -4 0 -28 -10 -51 -21z m-27 -111 c-33 -72 -34 -73 -74 -76 -23 -2 -41
1 -41 6 0 9 134 142 143 142 3 0 -9 -33 -28 -72z"/>
					<path d="M3520 2829 c-20 -10 -66 -20 -107 -23 -60 -4 -74 -2 -82 12 -8 13
-12 14 -25 3 -44 -37 2 -66 91 -58 35 4 63 4 63 0 0 -18 -132 -200 -151 -208
-29 -12 -48 -60 -31 -77 22 -22 61 9 111 89 141 223 139 220 175 227 37 7 62
29 51 46 -9 15 -55 10 -95 -11z"/>
					<path d="M3824 2830 c-23 -12 -78 -58 -123 -105 -70 -71 -88 -84 -119 -87 -26
-2 -38 -8 -40 -20 -2 -13 3 -18 17 -18 27 0 26 -2 -5 -50 -25 -38 -29 -70 -9
-76 6 -2 28 25 50 59 41 62 41 62 93 65 29 2 52 1 52 -1 0 -3 -11 -30 -25 -61
-30 -69 -30 -68 -3 -64 26 3 45 38 128 227 47 107 56 151 33 151 -4 0 -26 -9
-49 -20z m-12 -87 c-46 -98 -50 -103 -92 -103 -22 0 -40 3 -40 7 0 11 124 132
137 132 8 1 7 -10 -5 -36z"/>
					<path d="M4246 2834 c-93 -29 -156 -81 -156 -130 0 -41 14 -51 102 -78 88 -27
97 -46 38 -76 -58 -29 -133 -37 -164 -17 -27 18 -36 13 -36 -19 0 -35 33 -48
109 -40 76 7 149 40 170 77 30 53 -6 94 -108 125 -58 17 -66 26 -49 53 22 36
158 91 158 63 0 -4 20 -6 45 -4 39 4 45 7 45 27 0 38 -66 46 -154 19z"/>
					<path d="M4704 2829 c-53 -24 -181 -31 -194 -10 -7 12 -11 12 -24 2 -53 -44
37 -79 132 -52 31 9 32 9 20 -12 -38 -72 -124 -189 -146 -199 -32 -15 -52 -62
-34 -80 20 -20 57 7 98 72 130 205 155 238 186 244 36 7 58 23 58 42 0 20 -46
16 -96 -7z"/>
					<path d="M4930 2828 c-58 -29 -139 -110 -167 -169 -32 -64 -31 -128 2 -159 59
-55 186 16 280 159 15 21 33 60 40 85 12 40 12 48 -1 61 -9 8 -22 15 -30 15
-8 0 -13 6 -11 13 4 22 -65 19 -113 -5z m42 -47 c-34 -31 -39 -50 -21 -68 8
-8 19 -2 41 23 33 38 48 43 48 16 0 -38 -116 -178 -172 -208 -38 -20 -75 -18
-82 4 -19 60 88 208 178 248 44 19 44 18 8 -15z"/>
					<path d="M5258 2827 c-78 -27 -108 -50 -108 -84 0 -21 4 -24 28 -21 18 2 26 8
25 19 -3 15 73 59 103 59 20 0 24 7 -78 -146 -90 -134 -102 -164 -69 -164 13
0 31 21 57 65 21 36 41 65 44 65 3 0 18 -19 35 -42 53 -76 86 -108 110 -108
13 0 26 4 29 8 3 5 -22 37 -55 72 -32 35 -59 68 -59 74 0 6 23 19 50 29 71 25
122 77 118 118 -7 73 -111 98 -230 56z m166 -27 c33 -12 33 -30 -1 -62 -25
-23 -103 -68 -118 -68 -12 0 -3 23 26 62 17 24 28 50 26 60 -6 21 25 24 67 8z"/>
					<path d="M5700 2841 c-61 -19 -111 -53 -127 -86 -16 -34 -16 -36 7 -60 l23
-24 -41 -40 c-81 -79 -63 -161 37 -161 69 0 191 93 191 145 0 34 -22 31 -44
-6 -21 -35 -93 -85 -139 -95 -23 -5 -34 -2 -43 11 -28 38 40 110 133 144 30
10 49 24 51 35 4 17 -1 18 -61 12 -66 -7 -82 -1 -72 27 7 18 77 57 102 57 10
0 23 -4 29 -9 13 -13 65 -1 71 17 13 33 -54 52 -117 33z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
